import { PlayCircleOutlined, RightOutlined } from "@ant-design/icons"
import { theme, auth } from "@project/shared"
import styled from "styled-components"
import Router from "next/router"
import { MenuProps, Spin } from "antd"
import MenuLockIcon from "../../../public/assets/icons/menu_icon_lock.svg"
import MenuUnlockIcon from "../../../public/assets/icons/unlock_icon.svg"
import { LOCKITEMS_TYPES } from "../../../constants/lockItems"
import { signOut } from "firebase/auth"
import Link from "next/link"

const TagWrapper = styled.span`
  border-radius: 100px;
  margin-left: 10px;
  color: white;
  border: none;
  font-size: 16px;
  padding: 5px 14px;
  font-family: ${theme.font.BUS} !important;
  background: ${theme.button.primary};
`
const LabelWrapper = styled(Link)`
  font-family: ${theme.font.BUS};
  font-weight: ${theme.text.weight.regular};
  @media (max-width: 380px) {
    font-size: 18px !important;
  }
  @media (min-width: 380px) and (max-width: 750px) {
    font-size: 18px !important;
  }
`

const LabelWrapper2 = styled.span`
  font-family: ${theme.font.BUS};
  font-weight: ${theme.text.weight.regular};
  @media (max-width: 380px) {
    font-size: 18px !important;
  }
  @media (min-width: 380px) and (max-width: 750px) {
    font-size: 18px !important;
  }
`

const IconWrapper = styled.span`
  position: relative;
  top: 3px;
  left: 30px;
  @media only screen and (max-width: 765px) {
    left: 30px;
  }
`
const MenuLockWrapper = styled.span`
  position: relative;
  top: 3px;

  .home__icon path {
    stroke: red;
    transform: scale(1.5);
  }

  .home__icon_unlock path {
    transform: scale(1.5);
  }
`

/**
 *
 * @keys prefix are declared according to the mobile view items.
 *  i.e 1.home, 2.notes, 3.family, 4.inquiry and 5.settings
 */
export const dataFirstNav = (
  t: any,
  handleLockIconClick?: (item: string) => void,
  isLockedFirst?: boolean,
  isLockedSecond?: boolean,
  isLockedThird?: boolean,
  isLoading?: boolean
): MenuProps["items"] => [
  {
    key: "21",
    label: (
      <>
        <span onClick={() => localStorage.removeItem("filterMemo")}>
          <LabelWrapper href="/my-thoughts">{t("My thoughts")}</LabelWrapper>
        </span>
        <IconWrapper>
          {!isLoading ? (
            isLockedSecond ? (
              <MenuLockWrapper>
                <MenuLockIcon
                  className="home__icon"
                  height={30}
                  width={30}
                  onClick={() =>
                    handleLockIconClick(LOCKITEMS_TYPES?.my_thoughts)
                  }
                />
              </MenuLockWrapper>
            ) : (
              <MenuLockWrapper>
                <MenuUnlockIcon
                  className="home__icon_unlock"
                  height={30}
                  width={30}
                  onClick={() =>
                    handleLockIconClick(LOCKITEMS_TYPES?.my_thoughts)
                  }
                />
              </MenuLockWrapper>
            )
          ) : (
            <Spin />
          )}
        </IconWrapper>
      </>
    ),
    icon: <RightOutlined style={{ marginTop: "10px" }} />,
  },
  {
    key: "20",
    label: (
      <>
        <span onClick={() => localStorage.removeItem("filterMemo")}>
          <LabelWrapper href="/friends-property">
            {t("Friends・Property list")}
          </LabelWrapper>
        </span>
        <IconWrapper>
          {!isLoading ? (
            isLockedFirst ? (
              <MenuLockWrapper>
                <MenuLockIcon
                  className="home__icon"
                  height={30}
                  width={30}
                  onClick={() =>
                    handleLockIconClick(LOCKITEMS_TYPES?.friends_properties)
                  }
                />
              </MenuLockWrapper>
            ) : (
              <MenuLockWrapper>
                <MenuUnlockIcon
                  className="home__icon_unlock"
                  height={30}
                  width={30}
                  onClick={() =>
                    handleLockIconClick(LOCKITEMS_TYPES?.friends_properties)
                  }
                />
              </MenuLockWrapper>
            )
          ) : (
            <Spin />
          )}
        </IconWrapper>
      </>
    ),
    icon: <RightOutlined style={{ marginTop: "10px" }} />,
  },
  {
    key: "22",
    label: (
      <>
        <LabelWrapper href="/pictures-memories">
          {t("Share important pictures and memories")}
        </LabelWrapper>
        <IconWrapper>
          {!isLoading ? (
            isLockedThird ? (
              <MenuLockWrapper>
                <MenuLockIcon
                  className="home__icon"
                  height={30}
                  width={30}
                  onClick={() => handleLockIconClick(LOCKITEMS_TYPES?.medias)}
                />
              </MenuLockWrapper>
            ) : (
              <MenuLockWrapper>
                <MenuUnlockIcon
                  className="home__icon_unlock"
                  height={30}
                  width={30}
                  onClick={() => handleLockIconClick(LOCKITEMS_TYPES?.medias)}
                />
              </MenuLockWrapper>
            )
          ) : (
            <Spin />
          )}
        </IconWrapper>
      </>
    ),
    icon: <RightOutlined style={{ marginTop: "10px" }} />,
  },
]

export const dataSecondNav = (
  t: any,
  unApprovedNumber?: number
): MenuProps["items"] => [
  {
    key: "31",
    icon: <RightOutlined />,
    label: t("Family invitation"),
    onClick: () =>
      Router.pathname === "/invite-family"
        ? location.reload()
        : Router.push("/invite-family"),
  },
  {
    key: "32",
    icon: <RightOutlined />,
    label: (
      <>
        {t("Approvals")}
        <TagWrapper>
          <span>{unApprovedNumber}件</span>
        </TagWrapper>
      </>
    ),
    onClick: () => Router.push("/approve-family"),
  },
  {
    key: "30",
    icon: <RightOutlined />,
    label: t("Family list/Invited list"),
    onClick: () => Router.push("/family"),
  },
  {
    key: "33",
    icon: <RightOutlined />,
    label: t("Careful notes"),
    onClick: () => Router.push("/careful-notes"),
  },
]

export const dataThirdNav = (t: any): MenuProps["items"] => [
  {
    key: "40",
    icon: <RightOutlined />,
    label: t("Important inquiry"),
    onClick: () =>
      Router.pathname === "/inquiry"
        ? location.reload()
        : Router.push("/inquiry"),
  },
  {
    key: "42",
    icon: <RightOutlined />,
    label: t("Delete your account"),
    onClick: () => Router.push("/delete-account"),
  },
]

export const consultantMemoMenu = (
  t: any,
  handleLockIconClick?: (item: string) => void,
  isConsultantMemoLocked?: boolean,
  isLoading?: boolean
): MenuProps["items"] => [
  {
    key: "51",
    icon: <PlayCircleOutlined className="play-video-icon" />,
    label: t("相談メモの使い方"),
    onClick: () =>
      window.open("https://www.youtube.com/watch?v=mwRG0hPPLmg", "_blank"),
  },
  {
    key: "38",
    icon: <RightOutlined style={{ marginTop: "10px" }} />,
    label: (
      <>
        <span
          onClick={() => {
            const currentRoute = `${Router.pathname}`
            if (currentRoute === "/consultation-memo/admin") {
              // Reload the page
              location.reload()
            } else {
              Router.push("/consultation-memo/admin")
            }
          }}
        >
          <LabelWrapper2>{t("Consult record list")}</LabelWrapper2>
        </span>
        <IconWrapper>
          {!isLoading ? (
            <MenuLockWrapper>
              {isConsultantMemoLocked ? (
                <MenuLockIcon
                  className={"home__icon"}
                  height={30}
                  width={30}
                  onClick={() =>
                    handleLockIconClick(LOCKITEMS_TYPES?.consultations)
                  }
                />
              ) : (
                <MenuUnlockIcon
                  className={"home__icon_unlock"}
                  height={30}
                  width={30}
                  onClick={() =>
                    handleLockIconClick(LOCKITEMS_TYPES?.consultations)
                  }
                />
              )}
            </MenuLockWrapper>
          ) : (
            <Spin />
          )}
        </IconWrapper>
      </>
    ),
  },
  {
    key: "39",
    icon: <RightOutlined />,
    label: t("My memo list"),
    onClick: () => {
      const currentRoute = `${Router.pathname}`

      if (currentRoute === "/consultation-memo/user") {
        // Reload the page
        location.reload()
      } else {
        Router.push("/consultation-memo/user")
      }
    },
  },
  {
    key: "37",
    icon: <RightOutlined />,
    label: t("Consultation Memo・ Add"),
    onClick: () => {
      Router.pathname === "/consultation-memo/add"
        ? location.reload()
        : Router.push("/consultation-memo/add")
    },
  },
]

export const dataFourthNav = (
  t: any,
  handleLogout = (): void => {
    signOut(auth)
  }
): MenuProps["items"] => [
  {
    key: "50",
    icon: <RightOutlined />,
    label: t("Update information"),
    onClick: () => Router.push("/update-information"),
  },
  {
    key: "51",
    icon: <RightOutlined />,
    label: t("Logout"),
    onClick: handleLogout,
  },
]
